import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { motion } from 'framer-motion'

// Libraries
import parse from 'html-react-parser'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import ButtonOrangeArrow, { Arrow } from 'components/elements/ButtonOrangeArrow'
import ButtonOrangeBig from 'components/elements/ButtonOrangeBig'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string
}

const Blog: React.FC<BlogProps> = ({ fields }) => (
  <Blogik
    type={fields.viewtype}
    settings={{
      id: 'blog',
      limit: 50,
    }}
  >
    <BlogBanner fields={fields} />
    <BlogGrid fields={fields} />
  </Blogik>
)

const StyledBlogBanner = styled.section`
  background-color: ${(props) => props.theme.color.face.main};

  @media (min-width: 992px) {
    padding-top: 150px;
  }

  @media (max-width: 991px) {
    padding-top: 125px;
  }
`

const BlogBannerDescription = styled(ParseContent)<{ viewtype: string }>`
  ${(props) =>
    props.viewtype === 'blog' &&
    css`
      & h1,
      & h2,
      & h3,
      & h4,
      & h5 {
        font-size: ${props.theme.font.size.xxl} !important;
        line-height: 40px !important;
      }
    `};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p,
  & strong {
    color: ${(props) => props.theme.color.text.light};
  }

  @media (min-width: 992px) {
    padding-bottom: 75px;
  }
`

export const BlogBannerFilter = styled(BlogFilter)<{ selected: boolean }>`
  background-color: ${(props) => props.theme.color.face.light};
  color: ${(props) => props.theme.color.text.main};
  font-weight: ${(props) => props.theme.font.weight.s};
  border-radius: 20px;
  padding: 0.5rem 45px 0.5rem 1rem;
  position: relative;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    color: ${(props) => props.theme.color.text.contrast};
  }

  & svg {
    position: absolute;
    right: 8px;
    top: 50%;
    width: 28px;
    height: 28px;
    margin-top: -14px;

    & path {
      fill: ${(props) =>
        props.selected
          ? props.theme.color.text.contrast
          : props.theme.color.text.grey};
    }
  }
`

const BlogBanner: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpCategory: categories,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.BlogCategoryQueryQuery>(graphql`
    query BlogCategoryQuery {
      allWpCategory(
        filter: { id: { nin: ["dGVybTox", "dGVybTo3MzI=", "dGVybTo3MzU="] } }
      ) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <StyledBlogBanner className={fields.viewtype === 'blog' ? '' : 'pb-5'}>
      <div className="container">
        <div className="row">
          <div
            className={`${
              fields.viewtype === 'blog' ? 'col-lg-5 col-xl-4' : 'col-lg-12'
            } pt-lg-5 pb-lg-5 mb-lg-5`}
          >
            <BlogBannerDescription
              viewtype={fields.viewtype || ''}
              content={fields.description}
            />
          </div>
          {fields.viewtype === 'blog' && (
            <div className="col-lg-7 col-xl-8 pt-lg-5 pb-5 pb-lg-0 mb-5 mb-lg-0">
              <ParseContent content={fields.filterDescription} />

              <BlogConsumer>
                {(context: any) => (
                  <div className="mt-3 row">
                    {categories.edges.map((category) => (
                      <div
                        className="col-md-6 col-xl-4 mb-4"
                        key={category.node.id}
                      >
                        <BlogBannerFilter
                          selected={context.isSelected(category.node.slug)}
                          id={category.node.id}
                          slug={category.node.slug}
                          className="mr-3 d-flex align-items-center"
                        >
                          {category.node.name}
                          <svg
                            className="ml-3"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 496.124 496.124"
                          >
                            <g>
                              <path
                                d="M420.001,219.7c3.666,14.313,5.811,29.238,5.811,44.676c0,99.828-81.199,181.024-181.022,181.024
      c-99.821,0-181.039-81.195-181.039-181.024c0-99.824,81.218-181.041,181.039-181.041c25.442,0,49.633,5.338,71.623,14.848
      l26.912-43.26c-29.949-14.15-63.27-22.307-98.535-22.307c-127.793,0-231.764,103.971-231.764,231.76
      c0,127.779,103.971,231.748,231.764,231.748c127.774,0,231.743-103.969,231.743-231.748c0-35.516-8.271-69.062-22.599-99.178
      L420.001,219.7z"
                              />
                              <path
                                d="M463.159,6.385c-19.811-12.32-45.882-6.279-58.217,13.533L236.88,289.985l-56.299-80.289
      c-13.389-19.104-39.775-23.729-58.879-10.338c-19.1,13.408-23.742,39.756-10.336,58.877l93.002,132.633
      c7.93,11.291,20.855,17.996,34.609,17.996c0.379,0,0.777,0,1.157-0.018c14.181-0.396,27.229-7.871,34.72-19.914L476.702,64.6
      C489.05,44.766,482.972,18.713,463.159,6.385z"
                              />
                            </g>
                          </svg>
                        </BlogBannerFilter>
                      </div>
                    ))}
                  </div>
                )}
              </BlogConsumer>
            </div>
          )}
        </div>
      </div>
    </StyledBlogBanner>
  )
}

const StyledBlogGrid = styled.section<{ viewtype: string }>`
  position: relative;

  @media (min-width: 992px) {
    top: -150px;
  }

  ${(props) =>
    props.viewtype === 'blog' &&
    css`
      @media (max-width: 991px) {
        top: -75px;
      }
    `};
`

export const BlogGrid: React.FC<BlogProps> = ({ fields, className = '' }) => (
  <StyledBlogGrid
    className={`${className} ${
      fields.viewtype === 'blog' ? 'my-1 mt-lg-5' : 'mt-5'
    }`}
    viewtype={fields.viewtype || ''}
  >
    <div className="container">
      <BlogConsumer>
        {(context: any) => {
          const groupedPosts: any = {}
          let groupedIndex: number = 0

          context.posts.forEach((edge: any, index: number) => {
            if (index % 4 === 0) {
              groupedIndex += 1
            }

            if (!groupedPosts[groupedIndex]) {
              groupedPosts[groupedIndex] = []
            }

            groupedPosts[groupedIndex].push(edge)
          })

          return (
            <div>
              {Object.values(groupedPosts).map(
                (posts: any, groupIndex: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`row-${groupIndex}`} className="row mb-5">
                    <div className="col-md-6">
                      <div>
                        <Post big fields={posts[0].node} blogFields={fields} />
                      </div>
                      {posts[3] && (
                        <div className="mt-4 pt-2">
                          <Post fields={posts[3].node} blogFields={fields} />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 mt-4 mt-md-0">
                      {posts[1] && (
                        <div>
                          <Post fields={posts[1].node} blogFields={fields} />
                        </div>
                      )}
                      {posts[2] && (
                        <div className="mt-4 pt-2">
                          <Post
                            big
                            fields={posts[2].node}
                            blogFields={fields}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}

              {context.showMoreButton && (
                <div className="mt-5 text-center">
                  <ButtonOrangeBig to="/" isCustom>
                    <BlogButton>{fields.loadMoreText}</BlogButton>
                  </ButtonOrangeBig>
                </div>
              )}
            </div>
          )
        }}
      </BlogConsumer>
    </div>
  </StyledBlogGrid>
)

const StyledPost = styled(Link)`
  display: block;
`

const PostMotion = styled(motion.div)<{ big: boolean }>`
  background-color: ${(props) => props.theme.color.face.background};
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media (min-width: 992px) {
    display: ${(props) => (props.big ? 'block' : 'flex')};
  }

  @media (max-width: 991px) {
    display: block;
  }
`

const PostImage = styled(Image)<{ big: boolean }>`
  @media (min-width: 992px) {
    height: ${(props) => (props.big ? '375' : '275')}px;

    ${(props) =>
      props.big &&
      css`
        width: 100%;
      `};
    ${(props) =>
      !props.big &&
      css`
        min-width: 50%;
        max-width: 50%;
      `};
  }

  @media (max-width: 991px) {
    height: 250px;
    width: 100%;
  }

  @media (max-width: 575px) {
    height: 175px;
  }
`

const PostTitle = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  color: ${({ theme }) => theme.color.text.purple};

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size['28']};
    height: 65px;
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.xm};
    height: 50px;
  }
`

const PostDescription = styled(ParseContent)<{ big: boolean }>`
  color: ${({ theme }) => theme.color.text.purple};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: ${({ theme }) => theme.font.size.l};

  @media (min-width: 992px) {
    ${(props) =>
      props.big &&
      css`
        height: 70px;
        -webkit-line-clamp: 3;
      `};

    ${(props) =>
      !props.big &&
      css`
        height: 140px;
        -webkit-line-clamp: 6;
      `};
  }

  @media (max-width: 991px) {
    height: 100px;
    -webkit-line-clamp: 4;
  }
`

const PostLink = styled(ButtonOrangeArrow)`
  color: ${({ theme }) => theme.color.text.purple};
`

const PostContent = styled.div<{ big: boolean }>`
  @media (min-width: 992px) {
    ${(props) =>
      !props.big &&
      css`
        max-width: 50%;
      `};
  }
`

const Video = styled.video`
  object-fit: cover;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (min-width: 992px) {
    height: 315px;
  }

  @media (max-width: 575px) {
    height: 230px;
  }
`

const PostIcon = styled(Image)`
  width: 30px;
`

interface PostProps {
  big?: boolean
  fields: {
    title: string
    post: {
      icon?: any
      thumbnail: any
      videoThumbnail?: any
      excerpt: string
    }
    videoUrl?: string
    uri: string
  }
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const Post: React.FC<PostProps> = ({ big = false, fields, blogFields }) => {
  const autoplay = true
  const [isMuted, setMuted] = React.useState<boolean>(autoplay)
  const videoId: string = (fields?.videoUrl || fields?.videoUrl) as string

  React.useEffect(() => {
    if (!autoplay) {
      const video = document.getElementById(videoId) as HTMLVideoElement

      video.onloadeddata = () => {
        setTimeout(() => {
          video?.pause()
        }, 100)
      }
    }
  }, [autoplay])

  return (
    <StyledPost to={`${fields.uri}`}>
      <PostMotion
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        big={big}
      >
        {/* @ts-ignore */}
        {fields.post.videoThumbnail ? (
          /* @ts-ignore */
          <Video
            src={fields?.post.videoThumbnail}
            autoPlay={autoplay}
            controls={false}
            playsInline
            muted={isMuted}
            loop
          />
        ) : (
          <PostImage big={big} image={fields.post.thumbnail} alt="AddComm" />
        )}
        <PostContent big={big} className="py-3 px-2 px-md-4">
          <PostTitle>
            {parse(fields.title)}
            {fields.post.icon && (
              <PostIcon image={fields?.post.icon} className="ml-2" alt="" />
            )}
          </PostTitle>
          <PostDescription big={big} content={fields.post.excerpt} />
          <div className="text-right mt-3">
            {/* @ts-ignore */}
            {fields?.post.readMoreText ? (
              /* @ts-ignore */
              <ButtonOrangeBig to={fields.uri}>
                {fields?.post.readMoreText}
              </ButtonOrangeBig>
            ) : (
              <PostLink isCustom to={fields.uri}>
                {
                  /* @ts-ignore */
                  blogFields.readMoreText || blogFields.read_more_text
                }
                <Arrow />
              </PostLink>
            )}
          </div>
        </PostContent>
      </PostMotion>
    </StyledPost>
  )
}

export default Blog
